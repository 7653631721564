

  // ABOUT PAGE
  const officialBio = <><div className="bioParagraph">Ensemble Vitelotte is a violin-keyboard duo formed in 2019 with the mission of illuminating the stories and music of historical women composers. Sun Chang, pianist/harpsichordist, and Kiyoe Matsuura, violinist, met in Italy while studying at the summer baroque-music institute “Juilliard at the Piccola Accademia”. After discovering their shared musical interests, a natural collaboration unfolded. Since its inception, the ensemble has received funding from Luminarts Foundation and Ars Musica Chicago, and presented educational outreach events for Chicago public school students. This past season, Ensemble Vitelotte proudly performed the music of composers including Jacquet de la Guerre, Isabella Leonarda, Germaine Tailleferre, Pauline Viardot-Garcia, and Louise Farrenc at the Marilyn Horne Museum and the Landowska Harpsichord Society.</div>
                      <div className="bioParagraph">Currently, the duo is preparing to record two violin-keyboard sonatas by the romantic-era composer Emilie Mayer, with generous funding from Chicago’s Department of Cultural Affairs and Special Events. Ensemble Vitelotte strives to represent music within its historical context, studying from the original manuscripts and performing on period instruments whenever possible. In hopes of creating a deeper connection between listeners and the music, Ensemble Vitelotte actively engages their audiences with spoken program notes to humanize the stories and experiences of the composers.</div>
                      </>

const About = () => <>
<div>
<section>
      <h2>About Us</h2>
      <div className="bio-section">
        <span className="bio-photo left"><img src="../assets/mirror.jpg" alt="Vitelotte"></img></span>
        <span>{ officialBio }</span>
      </div>
      <div className="bio-section">
        <span className="bio-photo left desktop-only"><img src="../assets/kiyoe-action-shot.jpg" alt="Vitelotte"></img></span>
        <span className="bio-photo right"><img src="../assets/sun-action-shot.jpg" alt="Vitelotte"></img></span>
        <span style={{float:"center"}}>
          <h4>What is "Vitelotte?"</h4>
          Vitelotte is the name of a variety of purple potatoes. A small pun/joke about Jacquet de la Guerre/Jacket potatoes (as they call baked potatoes in the UK) turned into an ensemble name that sounds  pretentious, but is decidedly not.
          <h4>Sun</h4>
          Sun plays many keyboard instruments but mostly the piano. You can visit sunchangmusic.com for more information.
          <h4>Kiyoe</h4>
          Kiyoe is a violinist who also plays viola. Connect with her at <a href="https://www.kiyoematsuuraviolin.com">kiyoematsuuraviolin.com</a>.
        </span>
      </div>
        <span className="bio-photo right mobile-only"><img src="../assets/kiyoe-action-shot.jpg" alt="Vitelotte"></img></span>
      </section>
      </div>
    </>;

export default About;
